import React from "react"
import { PageContext } from "../context/pageProvider"
import { getImageUrl } from "../utils/getImageUrl"
import Footer from "./Footer/Footer"
import Navigation from "./navigation"

const ThanksPage = () => {
  const pageContext = React.useContext(PageContext)
  const { meta } = pageContext

  return (
    <div className="h-screen flex flex-col ">
      <Navigation />
      <div
        style={{
          backgroundImage: `url(
          ${getImageUrl(meta.ctaBlock.url?.asset?._ref)}
        )`,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          backgroundBlendMode: "multiply",
        }}
        className="ctaBlock "
      >
        <div className="elva ctaBlock_content">
          <h2 className="text-4xl ctaBlock_text">Tack</h2>
          <p className="mb-6 mt-4">
            Tack för ditt meddelande. Vi återkommer så snart vi kan.
          </p>
          <button
            onClick={() => {
              window.location.pathname = "/"
            }}
            className="ctaBlock_btn rounded table font-semibold px-9 py-4 text-center mx-auto my-2.5 leading-none"
          >
            Till Startsidan
          </button>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ThanksPage
