import React from "react"
import { PageProvider } from "../context/pageProvider"
import { useLocation } from "@reach/router"
import Seo from "../components/seo"
import ThanksPage from "../components/ThanksPage"

const thanksPageContainer = function ({ pageContext, location }) {
  return (
    <PageProvider data={pageContext} location={location}>
      <ThanksPage />
    </PageProvider>
  )
}
export default thanksPageContainer

export const Head = ({ pageContext }) => {
  const location = useLocation()
  return (
    <PageProvider data={pageContext} location={location}>
      <Seo
        seo={{
          author: "Addictive Media AB",
          description: "Thank you page",
          lang: "sv",
          title: "Thank you!",
          _type: "seo",
        }}
        children={<meta name="robots" content="noindex, nofollow"></meta>}
      />
    </PageProvider>
  )
}
